.form-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 0px;	
}

.add-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 1px solid black;
	border-radius: 0px 0px 50px 50px;
	align-self: center;
	width: 60%;
	background-color: hsla(0, 0%, 90%, 1);

}

.radio-btn-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.name-input-container {
	display: flex;
	justify-content: space-between;

	input {
		width: 60%;
	}
}

.input {
	margin: 10px;
	padding-right: 10%;
}
.radio-label {
	margin-right: 4px;
}
.name-input {
	height: 20px;
	font-size: 16px;
	
}
.submit-btn-container {
	align-self: center;
	input {
		font-size: 16px;
		width: 100%;
	}
}

.error-container {
	display: flex;
	justify-content: center;
	margin: 20px;
}

.radio-btn {
	border: 1px solid green;
}

.team-input-container {
	display: flex;
	justify-content: space-around;
}

.team-input {
	max-width: 60%;
	margin: 0px;
}
.team-input1 {
	min-width: 100%;
	overflow: visible;
}
.intro-title {
	padding: 20px;
	font-weight: bold;
}
.intro-text {
	padding: 10px;
}