.team-card {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	width: 200px;

	h4 {
		align-self: center;
	}
}

.player-card {
	border: 1px solid black;
	border-radius: 5px;
	padding: 10px 20px;
	width: 100px;
	margin: 20px;
	display: flex;
	justify-content: space-between;
	background-color: hsla(0, 0%, 90%, 1);
	min-height: 46px;
	h4 {
		//wrap longer words so they fit in the div 
		overflow-wrap: break-word;
		word-wrap: break-word;
		-ms-word-break: break-all;
		word-break: break-word;

		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
	button {
		max-height: 15px;	
	}

}

.player-card-text {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	h4 {
		padding: 2px 0px;
	}
}

.intro-container {
	display: flex;
	justify-content: center;
}
.intro {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 60%;
	border-radius: 10px;
	padding: 5px;
	border: 1px solid black;
	background-color: hsla(0, 0%, 90%, 1);
}

.hidden {
	position: absolute;
	visibility: hidden;
	z-index: 2;
}

