//container styling
body {
	background-image: url('../src/assets/images/grass.jpg');
}

.wrapper {
	margin: 10px 50px 10px 50px;
	border: 1px solid black;
	box-shadow: 0px 0px 104px -6px rgba(0,0,0,0.8);
	background-color: hsla(360, 100, 100, 0.9);
	border-radius: 10px;
}

.header-box {

	border: 1px solid black;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60%;
	min-height: 150px;
	background-color: hsla(0, 0%, 90%, 1);
	border-radius: 50px 50px 0px 0px;
	overflow: visable;

}

.header {
	display: flex;
	justify-content: center;
	align-content: center;	
	padding-top: 5%; 
}


footer {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.player-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;	
	margin: 50px 2% 10% 2%;
	min-height: 240px;
}

.team-list {
	display: flex;
	justify-content: space-around;
	margin: 5% 10%;

}

.error-container {
	display: inline-block;
}

.team-title {
	justify-content: center;
	align-self: center;
}

.footer-title {
	display: flex;
	flex-direction: column;
	height: 100px;
	border-top: 1px solid black;
}

.no-player {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.error-not-found {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.skill-btn-container {
	display: flex;
	justify-content: center;
}


