//image styling

.ref-image {
	
	height: 400px;
}

.four-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}